import routeUtil from '@/utils/routeUtil'
/**
 * 광고서비스-타겟마케팅 라우트
 */
const tarmktRoutes = {
    path: '/tarmkt',
    component: () => import('@/layouts/main/DefaultLayout.vue'),
    children: [
        {
            name: 'tarmktSrch',
            path: 'srch',
            component: () => import('@/views/tarmkt/TarmktSrch.vue'),
            beforeEnter: [routeUtil.settingHistoryStore],
            meta: {
                title: '광고서비스-타겟마케팅-탐색',
                requiresAuth: true
            }
        },
        {
            name: 'tarmktPt',
            path: 'pt/:id?',
            component: () => import('@/views/tarmkt/TarmktPt.vue'),
            meta: {
                title: '광고서비스-타겟마케팅-신청',
                requiresAuth: true
            }
        }
    ]
}

export default tarmktRoutes
