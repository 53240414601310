import routeUtil from '@/utils/routeUtil'

//마이페이지 레이아웃 라우트
const mypgLayoutRoutes = {
    path: '/mypg',
    component: () => import('@/layouts/main/MypgLayout.vue'),
    children: [
        {
            name: 'mypgInfo',
            path: 'mypginfo',
            component: () => import('@/views/mypg/info/MypgPsnInfo.vue'),
            meta: {
                title: 'MY-회원정보변경-조회',
                requiresAuth: true
            }
        },
        {
            name: 'mypgAre',
            path: 'are',
            component: () => import('@/views/mypg/MypgAre.vue'),
            beforeEnter: [routeUtil.settingHistoryStore],
            meta: {
                title: 'MY-나의계약',
                requiresAuth: true
            }
        },
        {
            name: 'mypgTarmkt',
            path: 'tarmkt',
            component: () => import('@/views/mypg/tarmkt/MypgTarmkt.vue'),
            beforeEnter: [routeUtil.settingHistoryStore],
            meta: {
                title: 'MY-타겟마케팅-목록',
                requiresAuth: true
            }
        },
        {
            name: 'mypgTarmktDetl',
            path: 'tarmkt/detl/:id',
            component: () => import('@/views/mypg/tarmkt/MypgTarmktDetl.vue'),
            meta: {
                title: 'MY-타겟마케팅-상세',
                requiresAuth: true
            }
        },
        {
            name: 'mypgProd',
            path: 'prod',
            component: () => import('@/views/mypg/MypgProd.vue'),
            beforeEnter: [routeUtil.settingHistoryStore],
            meta: {
                title: 'MY-관심상품',
                requiresAuth: true
            }
        },
        {
            name: 'mypgQna',
            path: 'qna',
            component: () => import('@/views/mypg/MypgQna.vue'),
            beforeEnter: [routeUtil.settingHistoryStore],
            meta: {
                title: 'MY-나의문의',
                requiresAuth: true
            }
        },
        {
            name: 'mypgNtfc',
            path: 'ntfc',
            component: () => import('@/views/mypg/MypgNtfc.vue'),
            beforeEnter: [routeUtil.settingHistoryStore],
            meta: {
                title: 'MY-알림',
                requiresAuth: true
            }
        }
    ]
}

//디폴트 레이아웃 라우트
const defaultLayoutRoutes = {
    path: '/mypg',
    component: () => import('@/layouts/main/DefaultLayout.vue'),
    children: [
        {
            name: 'mypgType',
            path: 'mypgtype',
            component: () => import('@/views/mypg/accchg/MypgType.vue'),
            meta: {
                title: 'MY-사업자계정전환 선택',
                requiresAuth: true
            }
        },
        {
            name: 'mypgPsnEtk',
            path: 'mypgpsnetk',
            component: () => import('@/views/mypg/accchg/MypgPsnEtk.vue'),
            meta: {
                title: 'MY-사업자계정전환 선택-개인사업자',
                requiresAuth: true
            }
        },
        {
            name: 'mypgCrpEtk',
            path: 'mypgcrpetk',
            component: () => import('@/views/mypg/accchg/MypgCrpEtk.vue'),
            meta: {
                title: 'MY-사업자계정전환 선택-법인사업자',
                requiresAuth: true
            }
        }
    ]
}

export default { mypgLayoutRoutes, defaultLayoutRoutes }
